import React, { useState, useEffect } from "react";
import { signUp } from "./hooks";
import logo from "../../assets/logo.png";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { BsArrowRight } from "react-icons/bs";
import FeaturesSlider from "./../../components/FeaturesSlider";
import FeatureList from "../../components/FeatureList";
import mixpanel from 'mixpanel-browser';

const Modal = ({ isOpen, onClose, onConfirm }: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-sm w-full">
        <h2 className="text-xl font-bold mb-4">Account Already Exists</h2>
        <p className="mb-6">Looks like you already have an account. Would you like to log in instead?</p>
        <div className="flex justify-end space-x-2">
          <button
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 text-sm font-medium text-white bg-primary rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            onClick={onConfirm}
          >
            Go to Login
          </button>
        </div>
      </div>
    </div>
  );
};

const Signup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
  });
  const [error, setError] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email.toLowerCase());
  };

  const validatePassword = (password: string) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~`-]).{8,}$/;
    return re.test(password);
  };

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      window.location.href = "/dashboard";
    }
  }, []);

  const validate = () => {
    if (formData.email === "" || formData.password === "" || formData.name === "") {
      setError("Please fill all the fields");
      return false;
    }
    if (!validateEmail(formData.email)) {
      setError("Please enter a valid email address");
      return false;
    }
    if (!validatePassword(formData.password)) {
      setError("Your password must meet the following criteria: it must include at least one uppercase letter, one lowercase letter, one special character, and be at least 8 characters long.");
      return false;
    }
    return true;
  };

  const handleSubmit = (e: React.FormEvent) => {
    if (isLoading) return;
    e.preventDefault();
    setError("");

    // Convert email to lowercase before validation and submission
    const submissionData = {
      ...formData,
      email: formData.email.toLowerCase()
    };

    if (validate()) {
      setIsLoading(true);
      signUp(submissionData)
        .then((response) => {
          console.log("API call success:", response);
          if (response.success) {
            localStorage.setItem("token", response.token);
            localStorage.setItem("userId", response.user.toString());
            localStorage.setItem("mailId", submissionData.email);
            localStorage.setItem('signup_response', JSON.stringify(response))
            const userId = localStorage.getItem('userId')
            mixpanel.identify(`UserId : ${userId}`)
            mixpanel.people.set({
              'name': submissionData.name,
              'email': submissionData.email,
            });
            mixpanel.track('Successfully Signed-Up');
            window.location.href = "/welcome/";
          } else {
            if (response.response === "user already exists") {
              setShowErrorModal(true);
            } else {
              setError(response?.response || "An error occurred. Please try again later.");
            }
          }
          setIsLoading(false);
        })
        .catch((error) => {
          if (error.response) {
            setShowErrorModal(true);
            setError(error.response.data.response);
          }
          console.error("API call failed:", error.response.data.response);
          setIsLoading(false);
        });
    }
  };

  const handleModalConfirm = () => {
    setShowErrorModal(false);
    window.location.href = "/signin";
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const lowercaseEmail = e.target.value.toLowerCase();
    setFormData({ ...formData, email: lowercaseEmail });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="min-h-screen w-full flex flex-col md:flex-row items-center justify-center bg-white">
      <Modal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        onConfirm={handleModalConfirm}
      />
      <div className="hidden md:hidden sm:hidden w-full md:w-1/2 min-h-screen p-8 overflow-y-auto lg:flex flex-col justify-center bg-gray-100 ">
        <div className="w-full max-w-md mx-auto flex-col justify-center p-8 shadow-lg bg-white rounded-md bg-">
          <FeaturesSlider />
          <p className="text-center text-gray-600 mt-4">Read more about our services</p>
        </div>
      </div>

      <div className="w-full md:w-1/2 min-h-screen p-8 overflow-y-auto flex flex-col justify-center">
        <div className="max-w-md mx-auto w-full">
          <img className="h-12 w-auto mx-auto mb-8 md:hidden" src={logo} alt="Outss Logo" />
          <img className="h-12 w-auto  mb-8" src={logo} alt="Outss Logo" />
          <p className="text-gray-600 mb-4">Get 1 month of free bookkeeping when you sign up today!</p>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">Full Name</label>
              <input
                id="name"
                type="text"
                required
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
                placeholder="John Doe"
                onClick={() => setError("")}
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email address</label>
              <input
                id="email"
                type="email"
                required
                value={formData.email}
                onChange={handleEmailChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary lowercase"
                placeholder="you@example.com"
                onClick={() => setError("")}
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  required
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-primary focus:border-primary"
                  placeholder="********"
                  onClick={() => setError("")}
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-500"
                >
                  {showPassword ? (
                    <RiEyeOffFill className="h-5 w-5" />
                  ) : (
                    <RiEyeFill className="h-5 w-5" />
                  )}
                </button>
              </div>
            </div>
            {error && <div className="text-red-500 text-sm">{error}</div>}
            <div>
              <button
                type="submit"
                disabled={isLoading}
                className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                {isLoading ? "Signing up..." : "Sign up & Get Started"}
                <BsArrowRight className="ml-2" />
              </button>
            </div>
          </form>
          <div className="mt-6 flex items-center justify-between">
            <div className="text-sm">
              <a href="/signin" className="font-medium text-primary hover:text-primary-dark">
                Already a member?
              </a>
            </div>
            <div className="text-sm">
              <a href="mailto:support@outss.co" className="font-medium text-primary hover:text-primary-dark">
                Need help?
              </a>
            </div>
          </div>
          <FeatureList />
        </div>
      </div>
    </div>
  );
};

export default Signup;