import React, { useState, useEffect } from "react";
import LoadingScreen from "../../components/LoadingScreen";
import createBusinessDetails, {
  updateBusinessDetails,
  FormData,
  getBusinessDetailsByUserId,
  getUserProfile,
  updateUserProfile,
  deleteAccount,
} from "./hooks";
import logo from "../../assets/logo.png";
import { GrSave, GrEdit } from "react-icons/gr";
import { FiDownload, FiTrash } from "react-icons/fi";
import axios from "axios";
import { getApiBaseUrl } from "../../config/constants";
import { PiCheckCircleThin } from "react-icons/pi";
import { CiCircleRemove } from "react-icons/ci";
import mixpanel from "mixpanel-browser";
import { Checkbox , Radio} from "@mantine/core";



interface OnBoardingProps {
  formData?: FormData | null;
}

interface UserProfile {
  id: number;
  name: string;
  email: string;
}

type DeletionReason = {
  reason: string;
  otherReason?: string;
};

const DeletionReasons = [
  "I no longer need this service",
  "I found a better alternative",
  "Service doesn't meet my needs",
  "Cost concerns",
  "Technical issues",
  "Privacy concerns",
  "Other"
];

const OnBoarding: React.FC<OnBoardingProps> = ({ formData }) => {
  const [data, setData] = useState<FormData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [otherIndustryType, setOtherIndustryType] = useState("");
  const [otherAccountingSoftware, setOtherAccountingSoftware] = useState("");
  const [businessDetails, setBusinessDetails] = useState<FormData | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [checked, setChecked] = useState(false);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  
  const [openModel, setOpenModel] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isDeleting, setIsDeleting] = useState<boolean | null>(null);
  const [error, setError] = useState(null);

  const [deletionReason, setDeletionReason] = useState<DeletionReason>({
    reason: "",
    otherReason: ""
  });
  const [deleteStep, setDeleteStep] = useState<'confirm' | 'reason' | null>(null);

  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const userId = localStorage.getItem("userId");
      if (userId) {
        const businessRes = await getBusinessDetailsByUserId(userId);
        if (businessRes.success) {
          setBusinessDetails(businessRes.data.business_details);
          setData(businessRes.data.business_details);
        }
        const profileRes = await getUserProfile(userId);
        if (profileRes.success) {
          setUserProfile(profileRes.data);
        }
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);


  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const target = e.target as HTMLInputElement;
    const { name, value, type, checked } = target;
    setBusinessDetails((prevDetails) => ({
      ...prevDetails!,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      !businessDetails ||
      !businessDetails.businessName ||
      !businessDetails.legalStructure ||
      !businessDetails.industryType ||
      !businessDetails.address
    ) {
      alert("Please fill in all the required fields.");
      return;
    }

    setIsLoading(true);
    let res;
    if (businessDetails.id) {
      // Update existing business details
      res = await updateBusinessDetails(businessDetails?.id.toString(), {
        ...businessDetails,
        industryType:
          businessDetails.industryType === "Other"
            ? otherIndustryType
            : businessDetails.industryType,
        accountingSoftware: ""
        // accountingSoftware:
        //   businessDetails.accountingSoftware === "Other"
        //     ? otherAccountingSoftware
        //     : businessDetails.accountingSoftware,
      });
    } else {
      // Create new business details
      res = await createBusinessDetails({
        ...businessDetails,
        industryType:
          businessDetails.industryType === "Other"
            ? otherIndustryType
            : businessDetails.industryType,
        accountingSoftware: ""
        // accountingSoftware:
        //   businessDetails.accountingSoftware === "Other"
        //     ? otherAccountingSoftware
        //     : businessDetails.accountingSoftware,
      });
    }
    setIsLoading(false);
    mixpanel.track('Business Details Updated',{
      email: localStorage.getItem('mailId'),
      platform: platform,
    });    

    if (res.id) {
      window.location.href = "/dashboard/myaccount";
    } else {
      alert("Error: " + res.message);
    }
  };

  const handleProfileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserProfile((prevProfile) => ({
      ...prevProfile!,
      [name]: value,
    }));
  };

  const handleProfileSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!userProfile) return;

    setIsLoading(true);
    const res = await updateUserProfile(userProfile.id.toString(), {
      ...userProfile,
      password: newPassword || undefined,
    });
    setIsLoading(false);

    if (res.success) {
      setIsEditingProfile(false);
      setNewPassword("");
      mixpanel.track('Profile Updated',{
        email: localStorage.getItem('mailId'),
        Platform: platform,
      });
      alert("Profile updated successfully");
    } else {
      alert("Error: " + res.message);
    }
  };

    // Function to detect the platform
    function detectPlatform() {
      var userAgent = navigator.userAgent || navigator.vendor ;
      if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window) {
          return "mobile";
      }
      return "web";
    }
  
    // Store the platform
    var platform = detectPlatform();

  const industryTypes = [
    "Technology",
    "Finance",
    "Healthcare",
    "Education",
    "Retail",
    "Automotive",
    "Hospitality",
    "Real Estate",
    "Entertainment",
    "Fashion",
    "Food and Beverage",
    "Travel",
    "Media",
    "Telecommunication",
    "Energy",
    "Manufacturing",
    "Construction",
    "Transportation",
    "Agriculture",
    "Government",
    "Other",
  ];
  const legalStructures = [
    "Sole Proprietorship",
    "Partnership",
    "Limited Liability Company (LLC)",
    "Corporation",
    "Non-Profit",
  ];
  // const accountingSoftwares = [
  //   "QuickBooks",
  //   "Xero",
  //   "FreshBooks",
  //   "Wave",
  //   "Sage",
  //   "Zoho Books",
  //   "NetSuite",
  //   "Microsoft Dynamics GP",
  //   "SAP Business One",
  //   "Oracle Financials",
  //   "Other",
  // ];

  // handle export user data
  const exportUserData = async () => {
    setIsExporting(true);
    setError(null);
    const userId = localStorage.getItem('userId');
    try {
      const response = await axios.get(`${getApiBaseUrl()}/export-user-data/${userId}`, {
        responseType: 'blob', // Important: This tells axios to treat the response as binary data
      });

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: 'text/csv' });

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element and trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `user_data_${userId}.csv`);
      document.body.appendChild(link);
      link.click();

      // // Clean up
      // link?.parentNode.removeChild(link);
      // window.URL.revokeObjectURL(url);

      setIsExporting(false);
    } catch (err) {
      // setError('An error occurred while exporting the data. Please try again.');
      setIsExporting(false);
      console.error('Error exporting user data:', err);
    }
  };

  const handleDelete = async () => {
    const userId = localStorage.getItem('userId');
    try {
      // Call deleteAccount with just the userId
      const response = await deleteAccount(userId?.toString() || '');
      
      setOpenModel(false);
      if (response.success) {
        setIsDeleting(true);
        // Track deletion reason in mixpanel
        mixpanel.track('Account Deleted', {
          email: localStorage.getItem('mailId'),
          platform: platform,
          reason: deletionReason.reason === 'Other' ? deletionReason.otherReason : deletionReason.reason
        });
        
        localStorage.clear();
        setTimeout(() => {
          window.location.href = '/';
        }, 1000);
      } else {
        setIsDeleting(false);
      }
    }
    catch (error) {
      console.error('Error deleting account:', error);
      setIsDeleting(false);
    }
  };

  const handleOpenDelete = () => {
    setDeleteStep('confirm');
    setOpenModel(true);
    setChecked(false); // Reset checkbox when opening modal
    setDeletionReason({ reason: "", otherReason: "" }); // Reset reason
};

  const renderDeleteModal = () => {
    if (deleteStep === 'confirm') {
      return (
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Delete Account</h2>
          <Checkbox
            className="text-gray-600 mb-6"
            checked={checked}
            onChange={(event) => setChecked(event.currentTarget.checked)}
            label="I understand that deleting my account is permanent and cannot be undone."
          />
          <div className="flex justify-end space-x-4">
            <button
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400"
              onClick={() => {
                setOpenModel(false);
                setChecked(false);
                setDeleteStep(null);
              }}
            >
              Cancel
            </button>
            <button
              className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700"
              onClick={() => setDeleteStep('reason')}
              disabled={!checked}
            >
              Continue
            </button>
          </div>
        </div>
      );
    }

    if (deleteStep === 'reason') {
      return (
        <div className="bg-white rounded-lg shadow-lg p-6 max-w-md">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Help Us Improve</h2>
          <p className="text-gray-600 mb-4">Please let us know why you're leaving:</p>
          
          <Radio.Group
            value={deletionReason.reason}
            onChange={(value) => setDeletionReason(prev => ({ ...prev, reason: value }))}
            className="space-y-3 mb-4"
          >
            {DeletionReasons.map((reason) => (
              <Radio
                key={reason}
                value={reason}
                label={reason}
                className="text-gray-700"
              />
            ))}
          </Radio.Group>

          {deletionReason.reason === 'Other' && (
            <textarea
              className="w-full p-2 border rounded-md mb-4"
              placeholder="Please specify your reason..."
              value={deletionReason.otherReason}
              onChange={(e) => setDeletionReason(prev => ({ ...prev, otherReason: e.target.value }))}
              rows={3}
            />
          )}

          <div className="flex justify-end space-x-4">
            <button
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400"
              onClick={() => setDeleteStep('confirm')}
            >
              Back
            </button>
            <button
              className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700"
              onClick={handleDelete}
              disabled={!deletionReason.reason || (deletionReason.reason === 'Other' && !deletionReason.otherReason)}
            >
              Delete Account
            </button>
          </div>
        </div>
      );
    }

    return null;
  };



  if (isLoading) return <LoadingScreen />;

  return (
    <div>
      <div className="fixed h-20 border-b px-4 lg:px-2 lg:pr-0 sm:pl-24 md:pl-24 justify-between items-center flex md:hidden flex-row lg:flex-row w-full bg-white z-10">
        <h1 className="text-2xl font-bold text-center lg:text-left mb-2 lg:mb-0 ml-14">
          My Account
        </h1>

        <div className="flex items-center ml-0 lg:ml-auto"></div>
      </div>
      <div className="w-full flex-col items-center bg-white pl-0 md:pl-2 ml-0 md:ml-4 pt-20 md:pt-0 lg:mt-6">
        <div className="flex-col md:flex-row justify-between mb-8 place-items-start max-w-2xl">
          <h1 className="text-2xl font-bold hidden md:block">My Account</h1>
          {businessDetails && !isEditing ? (
            <div className="mt-6 mx-6 md:mx-0">
              <h2 className="text-xl font-bold mb-4">Business Details</h2>
              <div className="bg-white rounded-lg shadow-md border border-gray-200">
                <div className="grid grid-cols-2 divide-y divide-gray-200">
                  <div className="p-4">
                    <p className="font-semibold">Business Name:</p>
                    <p>{businessDetails.businessName}</p>
                  </div>
                  <div className="p-4">
                    <p className="font-semibold">Legal Structure:</p>
                    <p>{businessDetails.legalStructure}</p>
                  </div>
                  <div className="p-4">
                    <p className="font-semibold">Industry Type:</p>
                    <p>{businessDetails.industryType}</p>
                  </div>
                  <div className="p-4">
                    <p className="font-semibold">Business Model:</p>
                    <p>{businessDetails.businessModel}</p>
                  </div>
                  
                  
                </div>
                <div className="p-4 border-t border-gray-200">
                  <p className="font-semibold">Address:</p>
                  <p>{businessDetails.address}</p>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                    onClick={() => setIsEditing(true)}
                  >
                    <GrEdit className="mr-2 h-5 w-5" />
                    Edit
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-6 mx-6 md:mx-0">
              <form onSubmit={handleSubmit}>
                <h2 className="text-xl font-bold">
                  {businessDetails
                    ? "Update Business Details"
                    : "Create Business Details"}
                </h2>
                <p className="text-gray-600 mb-5">
                  {businessDetails
                    ? "Update your business details"
                    : "Please fill in the details of your business"}
                </p>
                <input
                  type="text"
                  name="businessName"
                  placeholder="Name of the business"
                  className="block w-full px-3 py-2 mb-3 text-base border rounded-md"
                  value={businessDetails?.businessName || ""}
                  onChange={handleChange}
                  required
                />
                <select
                  name="legalStructure"
                  className="block w-full px-3 py-2 mb-3 text-base border rounded-md appearance-none bg-white focus:outline-none focus:shadow-outline-blue focus:border-primary"
                  value={businessDetails?.legalStructure || ""}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Legal Structure</option>
                  {legalStructures.map((structure, index) => (
                    <option key={index} value={structure}>
                      {structure}
                    </option>
                  ))}
                </select>
                <select
                  name="industryType"
                  className="block w-full px-3 py-2 mb-3 text-base border rounded-md appearance-none bg-white focus:outline-none focus:shadow-outline-blue focus:border-primary"
                  value={businessDetails?.industryType || ""}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Industry</option>
                  {industryTypes.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
                {businessDetails?.industryType === "Other" && (
                  <input
                    type="text"
                    name="otherIndustryType"
                    placeholder="Enter your industry"
                    className="block w-full px-3 py-2 mb-3 text-base border rounded-md"
                    value={otherIndustryType}
                    onChange={(e) => setOtherIndustryType(e.target.value)}
                    required
                  />
                )}
                <textarea
                  name="businessModel"
                  placeholder="Business Model"
                  className="block w-full px-3 py-2 mb-3 text-base border rounded-md"
                  value={businessDetails?.businessModel || ""}
                  onChange={handleChange}
                  rows={2}
                ></textarea>
                

                <textarea
                  name="address"
                  placeholder="Address"
                  className="block w-full px-3 py-2 mb-5 text-base border rounded-md"
                  value={businessDetails?.address || ""}
                  onChange={handleChange}
                  rows={2}
                  required
                ></textarea>
                <div className="flex justify-end space-x-2">
                  <button
                    type="submit"
                    className="bg-white text-primary text-base font-bold py-2 px-4 rounded hover:bg-primary hover:text-white flex justify-center items-center border-primary border"
                    onClick={() => {setIsEditing(false); setBusinessDetails(data)}}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-primary text-white text-base font-bold py-2 px-4 rounded hover:bg-primary flex justify-center items-center"
                  >
                    <GrSave className="mr-2" />
                    {businessDetails ? "Update" : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          )}

<div className="mt-12 mx-6 md:mx-0 max-w-2xl">
        <h2 className="text-xl font-bold mb-4">User Profile</h2>
        {userProfile && !isEditingProfile ? (
          <div className="bg-white rounded-lg shadow-md border border-gray-200">
            <div className="p-4">
              <p className="font-semibold">Name:</p>
              <p>{userProfile.name}</p>
            </div>
            <div className="p-4 border-t border-gray-200">
              <p className="font-semibold">Email:</p>
              <p>{userProfile.email}</p>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                onClick={() => setIsEditingProfile(true)}
              >
                <GrEdit className="mr-2 h-5 w-5" />
                Edit Profile
              </button>
            </div>
          </div>
        ) : (
          <form onSubmit={handleProfileSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Name"
              className="block w-full px-3 py-2 mb-3 text-base border rounded-md"
              value={userProfile?.name || ""}
              onChange={handleProfileChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="block w-full px-3 py-2 mb-3 text-base border rounded-md"
              value={userProfile?.email || ""}
              onChange={handleProfileChange}
              required
            />
            <input
              type="password"
              name="newPassword"
              placeholder="New Password (leave blank to keep current)"
              className="block w-full px-3 py-2 mb-3 text-base border rounded-md"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                className="bg-white text-primary text-base font-bold py-2 px-4 rounded hover:bg-primary hover:text-white flex justify-center items-center border-primary border"
                onClick={() => setIsEditingProfile(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-primary text-white text-base font-bold py-2 px-4 rounded hover:bg-primary flex justify-center items-center"
              >
                <GrSave className="mr-2" />
                Update Profile
              </button>
            </div>
          </form>
        )}
      </div>
      </div>

      {openModel && (
    <div 
        className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        onClick={(e) => {
            if (e.target === e.currentTarget) {
                setOpenModel(false);
                setDeleteStep(null);
                setChecked(false);
                setDeletionReason({ reason: "", otherReason: "" });
            }
        }}
    >
        {renderDeleteModal()}
    </div>
)}

      {isDeleting === null ? "" : (
        isDeleting === true ? 
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-96 flex flex-col h-44 text-center">
            <PiCheckCircleThin className="self-center h-20 w-20 text-green-600 mb-4" />
            <h2 className="text-xl font-bold text-green-600 mb-4">Deleted Successfully</h2>
          </div>
        </div> : 
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50" onClick={()=>setIsDeleting(null)}>
        <div className="bg-white rounded-lg shadow-lg p-6 w-96 flex flex-col h-44 text-center">
          <CiCircleRemove className="self-center h-20 w-20 text-red-600 mb-4" />
          <h2 className="text-xl font-bold text-red-600 mb-4">Failed To Delete</h2>
        </div>
      </div>)}

      <div className="mt-12 mx-6 md:mx-0 max-w-2xl border-b border-gray-200">
  <div className="bg-white rounded-lg shadow-md border border-gray-200 mb-10">
    <div className="p-4">
      <p className="font-semibold">Account Actions</p>
    </div>
    <div className="border border-gray-200 m-4 p-4 rounded-md bg-red-50">
      <p className="font-semibold mb-2">Request Account Deletion</p>
      <p className="text-gray-700 mb-4">
        If you wish to close your account, you can submit a deletion request. Once approved:
        <ul className="list-disc ml-6 mt-2 mb-2 space-y-1">
          <li>Your account and all associated data will be permanently removed</li>
          <li>This process cannot be reversed once completed</li>
          <li>You'll receive a confirmation email when your request is processed</li>
        </ul>
      </p>
      <div className="flex justify-end">
        <button
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          onClick={handleOpenDelete}
        >
          <FiTrash className="mr-2 h-5 w-5" />
          Request Account Deletion
        </button>
      </div>
    </div>
  </div>
</div>
        <p className="text-gray-400">VersionCode : 27.12.24</p>
      </div>


      

    </div>
  );
};

export default OnBoarding;
