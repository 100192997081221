import React from 'react';
import { BrowserRouter, Routes as Switches, Route } from 'react-router-dom';
import Dashboard from './financial-management/pages/Dashboard';
import Signup from './pages/Signup';
import Statements from './financial-management/pages/Bookkeeping/Statements';
import { Navigate } from 'react-router-dom';
import DashboardRoot from './accountants/pages/DashboardRoot';
import ForgotPassword from './pages/Signup/ForgotPassword';
import ResetPassword from './pages/Signup/ResetPassword';
import TrialActivation from './financial-management/pages/Payments/TrialActivation';
import AccountantsLogin from './accountants/pages/AccountantsLogin';
import AdminLoginPage from './admin/pages/AdminLoginPage';
import AdminDashboard from './admin/pages/AdminDashboard';
import StreamChatHome from './accountants/pages/StreamChatHome';
import Unsubscribe from './commonpages/Unsubscribe';
import WelcomBusiness from './financial-management/pages/WelcomeBusiness';
import EmailVerification from './pages/VerifyEmail';
import BankStatementView from './components/PlaidIntegrationConcise/BankStatementView';
import SignIn from './pages/Signup/SignIn';
import APAgingReport from './virtualaccountant/pages/APAgingReport';
import ARAgingReport from './virtualaccountant/pages/ARAgingReport';




  const Routes = () => {
    const userId = localStorage.getItem('userId');

    if (userId) {
      return (
        <BrowserRouter>
          <Switches>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/welcome" element={<WelcomBusiness />} />
            <Route path="/statements" element={<Statements />} />
            <Route path="/accountant/*" element={<DashboardRoot />} />
            <Route path="/forgot-password/" element={<ForgotPassword />} />
            <Route path="/trialactivate" element={<TrialActivation />} />
            <Route path="/reset-password/" element={<ResetPassword />} />
            <Route path="/accountant/login" element={<AccountantsLogin />} />
            <Route path="/admin/login/" element={<AdminLoginPage /> } />
            <Route path="/admin/dashboard/" element={<AdminDashboard /> } />
            <Route path="/streamchat/" element={<StreamChatHome />} />
            <Route path="/unsubscribe/" element={<Unsubscribe />} />
            <Route path="/verify-email/:token" element={<EmailVerification />} />
            <Route path="/plaid" element={<BankStatementView userId={userId} />} />
            <Route path="/accountant/forgot-password" element={<ForgotPassword />} />
            <Route path='/apagingreport/' element={<APAgingReport />} />
            <Route path="/aragingreport/" element={<ARAgingReport />} /> 
          </Switches>
        </BrowserRouter>
      );
    } else {
      return (
        <BrowserRouter>
          <Switches>
            <Route path="/" element={<Navigate to="/signin" />} />
            <Route path="/dashboard/*" element={<Navigate to="/signin" />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/welcome" element={<WelcomBusiness />} />
            <Route path="/statements" element={<Statements />} />
            <Route path="/accountant/*" element={<DashboardRoot />} />
            <Route path="/forgot-password/" element={<ForgotPassword />} />
            <Route path="/reset-password/" element={<ResetPassword />} />
            <Route path="/trialactivate" element={<TrialActivation />} />
            <Route path="/accountant/login" element={<AccountantsLogin />} />
            <Route path="/accountant/forgot-password" element={<ForgotPassword />} />
            <Route path="/admin/login/" element={<AdminLoginPage /> } />
            <Route path="/admin/dashboard/" element={<AdminDashboard /> } />
            <Route path="/streamchat/" element={<StreamChatHome />} />
            <Route path="/unsubscribe/" element={<Unsubscribe />} />
            <Route path="/verify-email/:token" element={<EmailVerification />} />
            <Route path="/wip/" element={<Signup />} />
          </Switches>
        </BrowserRouter>
      );
    }
  };


export default Routes;