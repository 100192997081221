import React, { useEffect, useState } from "react";
import { FaMoneyBillAlt, FaShoppingCart, FaChartLine, FaIndustry } from "react-icons/fa";
import { getApiBaseUrl } from "../../../../config/constants";

// Assuming Card component is imported correctly
import Card from "../elements/Card";

interface IncomeData {
  total_income: string | number;
  total_cogs: string | number;
  total_expenses: string | number;
  net_income: string | number;
}

const ProfitLoss = () => {
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [incomeData, setIncomeData] = useState<IncomeData>({
    total_income: 0,
    total_cogs:0,
    total_expenses: 0,
    net_income: 0,
  });
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31)
  );
  const [isDemo, setIsDemo] = useState(false);

  useEffect(() => {
    fetchIncomeStatement();
  }, [startDate, endDate]);

  const fetchIncomeStatement = async () => {
    if (startDate && endDate) {
      try {
        const userId = localStorage.getItem("userId") || 1;
        const response = await fetch(
          `${getApiBaseUrl()}/profit-and-loss/${userId}/?start_date=${startDate.toISOString().split('T')[0]}&end_date=${endDate.toISOString().split('T')[0]}`
        );
        const data = await response.json();
        console.log(data);
        if (data.response && isValidData(data.response)) {
          setIncomeData(data.response);
          setIsDemo(false);
        } else {
          setDummyData();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setDummyData();
      }
    }
  };

  const isValidData = (data: IncomeData) => {
    return (
      typeof data.total_income !== 'undefined' &&
      typeof data.total_expenses !== 'undefined' &&
      typeof data.net_income !== 'undefined'
    );
  };

  const setDummyData = () => {
    setIncomeData({
      total_income: 500000,    
      total_cogs: 150000,     
      total_expenses: 125000, 
      net_income: 225000      
    });
    setIsDemo(true);
  };

  const formatAmount = (amount: string | number | null | undefined): string => {
    if (amount === null || amount === undefined) {
      return '$0.00';
    }
    
    const numAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
    
    return isNaN(numAmount) 
      ? '$0.00' 
      : `$${numAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  };
  return (
    <div className="p-2 mx-3 md:mx-0">
      <div className="flex flex-row items-center justify-between mb-4">
        <div className="flex items-center space-x-4">
          <h1 className="text-xl font-semibold text-black">Profit & Loss</h1>
          <span className="rounded-full bg-[#69339C] text-white py-1 px-2 font-400 text-sm">
            This year
          </span>
          {isDemo && (
            <span className="px-2 py-1 bg-blue-100 text-blue-800 text-xs font-medium rounded">
            Demo Data
          </span>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
        <Card
          title="Total Revenue"
          amount={formatAmount(incomeData.total_income)}
          icon={<FaMoneyBillAlt className="text-[#69339C]" size={20}/>}
        />
        <Card
          title="Total Cost of Goods Sold"
          amount={formatAmount(incomeData.total_cogs)}
          icon={<FaIndustry className="text-[#69339C]" size={20}/>}
        />
        <Card
          title="Total Expenses"
          amount={formatAmount(incomeData.total_expenses)}
          icon={<FaShoppingCart className="text-[#69339C]" size={20}/>}
        />
        <Card
          title="Net Profit"
          amount={formatAmount(incomeData.net_income)}
          icon={<FaChartLine className="text-[#69339C]" size={20}/>}
        />
      </div>
    </div>
  );
};

export default ProfitLoss;