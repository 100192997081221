
const ENV = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

const SERVER_URLS = {
  [ENV.DEVELOPMENT]: 'https://apiv2.outss.co',
  [ENV.STAGING]: 'https://apiv2.outss.co',
  [ENV.PRODUCTION]: 'https://apiv2.outss.co',
};

const getServerUrl = () => {
  const environment = process.env.REACT_APP_ENV || ENV.DEVELOPMENT;
  return SERVER_URLS[environment];
};

const getApiBaseUrl = () => {
  return `${getServerUrl()}/api`;
};

export { ENV, getServerUrl, getApiBaseUrl };

