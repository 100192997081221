import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { getApiBaseUrl } from "../../../config/constants";
import ChatWindowAccountant from "../../../financial-management/pages/ChatWindow/ChatWindowAccountant";
import ChatWindowSuccessManager from "../../../financial-management/pages/ChatWindow/ChatWindowSuccessManager";
import BankStatements from "../BankStatements";
import AccTransactionReports from "../ClientTabs/AccTransactionReports";
import CreateInvoice from "../CreateInvoice";
import WorkMailAccountDisplay from "../WorkMailAccountDisplay";
import StreamChatAccountant from "../StreamChatAccountant";
import AccountantPlaidView from "../AccountantPlaidView";
import { ReviewTable } from "../../../components/ReviewTable";
import { FileData } from "../../../financial-management/pages/MyBooks";
import { useMediaQuery } from "react-responsive";
import { FormData } from "../../../pages/OnBoarding/hooks";

interface UserData {
  id: number;
  name: string;
  email: string;
  user_type: string;
  quickbook_id: string | null;
  stripe_customer_id: string | null;
  created_in: string;
}

const UserView: React.FC = () => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [businessData, setBusinessData] = useState<FormData | null>(null)
  const [activeTab, setActiveTab] = useState("profile");
  const [tableData, setTableData] = useState<FileData[]>()
  const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

  const { id } = useParams<{ id: string }>();
  console.log("id:", id);

  const [searchParams] = useSearchParams();
  const businessid = searchParams.get('businessid');
  console.log('businessid', businessid);
  

  const fetchBusinessDetails = async() => {
    try {
      const response = await axios.get(
        `${getApiBaseUrl()}/business-details/${businessid}/`
      );
      setBusinessData(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      console.log("fetching user data...", id);    
      try {
        const response = await axios.get<UserData>(
          `${getApiBaseUrl()}/users/${id}/`
        );
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
    fetchBusinessDetails()
  }, [id]);

  useEffect(() => {
    const fetchTable = async () => {
      console.log("fetching Table...", id);    
      try {
        const response = await axios.get(
          `${getApiBaseUrl()}/acc/bankstatements/${id}/`
        );
        setTableData(response.data)
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchTable();
  }, []);

  if (!userData) {
    return <div className="flex items-center justify-center z-50 bg-white h-screen">
      <div className="flex flex-col items-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
        <p className="text-xl font-semibold text-primary mt-4">Loading...</p>
      </div>
    </div>;
  }

  return (
    <div className="bg-white min-h-screen">
      <div className="container mx-auto">
        <h1 className={`text-3xl font-bold mb-6 ${isMobile && 'text-center'}`}>{userData.name}</h1>
        <div className={`bg-white rounded-lg ${!isMobile && 'pr-10'}`}>
          <ul className="flex border-b border-gray-300 overflow-auto">
            <li
              className={`px-4 py-2 cursor-pointer ${
                activeTab === "profile"
                  ? "border-b-2 border-primary text-primary"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab("profile")}
            >
              Profile
            </li>
            {/* <li
              className={`px-4 py-2 cursor-pointer ${
                activeTab === "bankStatements"
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab("bankStatements")}
            >
              Bank Statements
            </li> */}
            <li
              className={`px-4 py-2 cursor-pointer ${
                activeTab === "business"
                  ? "border-b-2 border-primary text-primary"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab("business")}
            >
              Business
            </li>
            <li
              className={`px-4 py-2 cursor-pointer ${
                activeTab === "inbox"
                  ? "border-b-2 border-primary text-primary"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab("inbox")}
            >
              Inbox
            </li>
            {/* <li
              className={`px-4 py-2 cursor-pointer ${
                activeTab === "success"
                  ? "border-b-2 border-primary text-primary"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab("success")}
            >
              Success Manager
            </li> */}
            {/* <li
              className={`px-4 py-2 cursor-pointer ${
                activeTab === "bankstatements"
                  ? "border-b-2 border-primary text-primary"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab("bankstatements")}
            >
              Bank Statements
            </li> */}
            <li
              className={`px-4 py-2 cursor-pointer ${
                activeTab === "fileupload"
                  ? "border-b-2 border-primary text-primary"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab("fileupload")}
            >
              Documents
            </li>
            <li
              className={`px-4 py-2 cursor-pointer ${
                activeTab === "bills"
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab("bills")}
            >
              Invoice
            </li>
            <li
              className={`px-4 py-2 cursor-pointer ${
                activeTab === "reports"
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab("reports")}
            >
              Transaction Reports
            </li>
            <li
              className={`px-4 py-2 cursor-pointer ${
                activeTab === "createemail"
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab("createemail")}
            >
              Email Accounts
            </li> 
          </ul>
          <div className="">
            {activeTab === "profile" && (
              <div className="grid grid-cols-2 gap-6 p-5 border border-gray-200 shadow-xl rounded-lg mt-5 overflow-auto">
                <div>
                  <p className="font-bold mb-2">Name:</p>
                  <p>{userData.name}</p>
                </div>
                <div>
                  <p className="font-bold mb-2">Email:</p>
                  <p>{userData.email}</p>
                </div>
                <div>
                  <p className="font-bold mb-2">User Type:</p>
                  <p>{userData.user_type || "N/A"}</p>
                </div>
                <div>
                  <p className="font-bold mb-2">QuickBooks ID:</p>
                  <p>{userData.quickbook_id || "N/A"}</p>
                </div>
                <div>
                  <p className="font-bold mb-2">Stripe Customer ID:</p>
                  <p>{userData.stripe_customer_id || "N/A"}</p>
                </div>
                <div>
                  <p className="font-bold mb-2">Created On:</p>
                  <p>{new Date(userData.created_in).toLocaleDateString()}</p>
                </div>
              </div>
            )}
            {activeTab === "business" && businessData && (
              <div className="grid grid-cols-2 gap-6 p-5 border border-gray-200 shadow-xl rounded-lg mt-5">
                {/* Business data */}
                <div>
                  <p className="font-bold mb-2">Business name:</p>
                  <p>{businessData.businessName}</p>
                </div>
                <div>
                  <p className="font-bold mb-2">Legal Structure:</p>
                  <p>{businessData.legalStructure}</p>
                </div>
                <div>
                  <p className="font-bold mb-2">Industry Type:</p>
                  <p>{businessData.industryType}</p>
                </div>
                <div>
                  <p className="font-bold mb-2">Business Model:</p>
                  <p>{businessData.businessModel}</p>
                </div>
                <div>
                  <p className="font-bold mb-2">Accounting Software:</p>
                  <p>{businessData.accountingSoftware}</p>
                </div>
                <div>
                  <p className="font-bold mb-2">Chart of Accounts:</p>
                  <p>{businessData.chartOfAccounts ? "Yes" : "No"}</p>
                </div>
                <div>
                  <p className="font-bold mb-2">Tax ID Number:</p>
                  <p>{businessData.taxIdNumber}</p>
                </div>
                <div>
                  <p className="font-bold mb-2">Address:</p>
                  <p>{businessData.address}</p>
                </div>
            </div>
            )}
            {activeTab === "bankStatements" && (
              <div>
                <BankStatements />
              </div>
            )}
            {activeTab === "inbox" && (
              <div>
                {/* <ChatWindowAccountant /> */}
                <StreamChatAccountant />
              </div>
            )}
            {activeTab === "success" && (
              <div>
                <ChatWindowSuccessManager />
              </div>
            )}
            {activeTab === "bankstatements" && (
                <div>
                  <AccountantPlaidView />
                </div>
            )}
            {activeTab === "bills" && (
              <div>
                <CreateInvoice />
              </div>
            )}
            {activeTab === "reports" && (
              <div>
                <AccTransactionReports />
              </div>
            )}
            {activeTab === "createemail" && (
              <div>
                <WorkMailAccountDisplay />
              </div>
            )}
            {activeTab === "fileupload" && (
              <div>
                <ReviewTable files={tableData} />
              </div>
            )}
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserView;