import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import CustomDatePicker from "../../../components/CustomDatePicker";
import { ApiResponse } from "../../types/StatementTypes";

import { ErrorBoundary } from "react-error-boundary";
import { FaArrowDown, FaArrowUp, FaDownload } from "react-icons/fa";
import { MdOutlineCalendarMonth } from "react-icons/md";
import Loader from "react-js-loader";
import { getHumanReadableDate } from "../../utils/index";
import { getApiBaseUrl } from "../../../config/constants";
import mixpanel from "mixpanel-browser";
import fileDownload from "js-file-download";
import * as XLSX from 'xlsx-js-style';
import { saveAs } from 'file-saver';

type DayRange = {
  from: Date | undefined;
  to: Date | undefined;
};

const fetchIncomeStatement = async (
  userId: string | null,
  startDate: string,
  endDate: string
) => {
  const response = await fetch(
    `${getApiBaseUrl()}/profit-and-loss/${userId}/?start_date=${startDate}&end_date=${endDate}`,{
    headers: {
      'Authorization': `Token ${localStorage.getItem("token")}`,
    },
    }
  );
  const data = await response.json();
  return data.success ? data.response : null;
};

const IncomeStatement = () => {
  const [expandedCategories, setExpandedCategories] = useState<string[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<DayRange>({
    from: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
    to: new Date(),
  });
  const [showDownload, setShowDownload] = useState (false)
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const userId = localStorage.getItem("userId") || null;
  const startDate = selectedDateRange.from?.toLocaleDateString('en-CA') || ""; 
  const endDate = selectedDateRange.to?.toLocaleDateString('en-CA') || "";
  console.log('startDate',startDate);
  console.log('endDate', endDate);  
  
  const {
    data: incomeData,
    isLoading,
    isError,
  } = useQuery<ApiResponse | null>(
    ["incomeStatement", userId, startDate, endDate],
    () => fetchIncomeStatement(userId, startDate, endDate),
    {
      enabled: !!userId && !!startDate && !!endDate,
      staleTime: 60 * 1000, // Cache for 1 minute
    }
  );

  useEffect(() => {
    // Track page visit with Mixpanel
    mixpanel.track('Income Statement Visited');
  }, []);

  useEffect(() => {
    console.log('incomeData',incomeData);    
    if(incomeData?.total_income === '0' || incomeData?.total_expenses === '0' || incomeData?.net_income === '0'){
      setShowDownload(false)
    }else{
      setShowDownload(true)
    }
  }, [incomeData]);
  
  const handleDateChange = (dateRange: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  }) => {
    setSelectedDateRange({ from: dateRange.startDate, to: dateRange.endDate });
  };

  const toggleCategory = (category: string) => {
    if (expandedCategories.includes(category)) {
      setExpandedCategories(expandedCategories.filter((c) => c !== category));
    } else {
      setExpandedCategories([...expandedCategories, category]);
    }
  };

  const openDatePicker = () => {
    setIsDatePickerOpen(true);
  };

  const closeDatePicker = () => {
    setIsDatePickerOpen(false);
  };

  const formatDate = (dateStr: string): string => {
    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options); // "March 31, 2024"
  };    

  const generateExcel = () => {
    if (!incomeData || typeof incomeData === "string") return;
  
    // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([]);
  
    // Helper function to add a row with optional styling
    const addRow = (row: (string | number)[], styles: XLSX.CellStyle = {}) => {
      XLSX.utils.sheet_add_aoa(ws, [row], { origin: -1 });
      const range = XLSX.utils.decode_range(ws['!ref'] || 'A1');
      const rowIndex = range.e.r;
      for (let i = 0; i < row.length; i++) {
        const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: i });
        if (ws[cellRef]) {
          ws[cellRef].s = styles;
        }
      }
    };
  
    // Styles
    const boldStyle: XLSX.CellStyle = { font: { bold: true } };
    const underlineStyle: XLSX.CellStyle = { font: { underline: true } };
    const boldUnderlineStyle: XLSX.CellStyle = { font: { bold: true, underline: true } };
  
    // Helper function to format number as positive or return "0.00" if empty
    const formatNumber = (value: string | number | undefined): string => {
      if (value === undefined || value === "" || isNaN(Number(value))) {
        return "0.00";
      }
      // Convert value to a number and format it with two decimal places
      const numValue = Number(value);
      return numValue.toFixed(2);
    };    
  
    // Title and date range
    addRow(["Income Statement (Profit and Loss)"], boldUnderlineStyle);
    addRow([`As of ${formatDate(endDate)}`], boldStyle);
    addRow([]);  
  
    // Income
    addRow(["Income"], boldUnderlineStyle);
    incomeData.operating_income.forEach((category) => {
      addRow([category.name, formatNumber(category.amount)]);
    });
    addRow(["Total Income", formatNumber(incomeData.total_income)], boldStyle);
    addRow([]);  // Empty row
  
    // Cost of Goods Sold (placeholder)
    addRow(["Cost of Goods Sold"], boldUnderlineStyle);
    incomeData.cogs_items.forEach((category) => {
      addRow([category.name, formatNumber(category.amount)]);
    });
    addRow(["Total Cost of Goods Sold", formatNumber(incomeData.total_cogs)], boldStyle);
    addRow([]);  // Empty row
  
    // Gross Profit
    const grossProfit = Number(formatNumber(incomeData.total_income)) - Number(formatNumber(incomeData.total_cogs));
    addRow(["Gross Profit", formatNumber(grossProfit)], boldUnderlineStyle);
    addRow([]);  // Empty row
  
    // Operating Expenses
    addRow(["Operating Expenses"], boldUnderlineStyle);
    incomeData.operating_expenses.forEach((category) => {
      addRow([category.name, formatNumber(category.amount)]);
    });
    addRow(["Total Operating Expenses", formatNumber(incomeData.total_expenses)], boldStyle);
    addRow([]);  // Empty row
  
    // Operating Income
    const operatingIncome = Number(formatNumber(incomeData.total_income)) - Number(formatNumber(incomeData.total_expenses));
    addRow(["Operating Income", formatNumber(operatingIncome)], boldUnderlineStyle);
    addRow([]);  // Empty row
  
    // Other Income / (Expense) (placeholder)
    addRow(["Other Income / (Expense)"], boldUnderlineStyle);
    addRow(["Total Other Income / (Expense)", "0.00"], boldStyle);
    addRow([]);  // Empty row
  
    // Net Income
    addRow(["Net Income", formatNumber(incomeData.net_income)], boldUnderlineStyle);
    addRow([]);

    addRow([new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) + ', ' + new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })], boldStyle);
  
    // Auto-size columns
    const range = XLSX.utils.decode_range(ws['!ref'] || 'A1');
    const cols: XLSX.ColInfo[] = [];
    for (let i = 0; i <= range.e.c; i++) {
      let max = 0;
      for (let j = 0; j <= range.e.r; j++) {
        const cellRef = XLSX.utils.encode_cell({ r: j, c: i });
        const cell = ws[cellRef];
        if (cell && cell.v) {
          max = Math.max(max, String(cell.v).length);
        }
      }
      cols[i] = { wch: max };
    }
    ws['!cols'] = cols;
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Income Statement");
  
    // Generate Excel file
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Use file-saver to trigger download
    saveAs(data, `income_statement_${startDate}_to_${endDate}.xlsx`);
  
    // Track Excel download with Mixpanel
    mixpanel.track('Income Statement Excel Downloaded', {
      startDate,
      endDate
    });
  };

  if (isLoading) {
    return (
      <div className="w-full h-screen flex items-center justify-center bg-center">
        <Loader
          type="bubble-top"
          bgColor={"#69339C"}
          color={"#69339C"}
          title={"knocking your accounts..."}
          size={20}
        />
      </div>
    );
  }

  if (isError) {
    return <div>Error occurred while fetching income statement.</div>;
  }

  return (
    <div>
      <div className="fixed h-20 border-b px-4 lg:px-2 lg:pr-0 sm:pl-24 md:pl-24 justify-between items-center flex md:hidden flex-row lg:flex-row w-full bg-white z-10">
        <h1 className="text-2xl font-bold text-center lg:text-left mb-2 lg:mb-0 ml-14">
          Income Statement
        </h1>

        

        <div className="flex items-center ml-0 lg:ml-auto"></div>
      </div>
      <div className="w-full flex-col items-center bg-white pl-0 md:pl-2 ml-0 md:ml-4 mt-0 lg:mt-6 ">
        <div className="hidden md:flex flex-col md:flex-row justify-between mb-8 place-items-start">
          <h2 className="text-xl md:text-2xl font-bold mb-4 w-full md:w-auto">
            Income Statement
          </h2>
          <div className="w-full md:w-auto flex flex-row space-x-2 md:space-x-4 place-items-center justify-center">

          {showDownload &&  
          <button
          onClick={generateExcel}
          className="bg-white text-primary px-4 py-2 rounded-md hover:bg-primary-dark flex items-center border border-primary shadow-md"
        >
          <FaDownload className="mr-2" />
          Download ( Excel )
        </button>
          }
            <button
              onClick={openDatePicker}
              className="flex flex-row space-x md:space-x-2 place-items-center justify-center bg-primary  border-solid text-black text-sm py-2 px-2 md:px-4 rounded-md hover:bg-primary group shadow-md"
            >
              <MdOutlineCalendarMonth className="text-lg md:text-xl text-gray-200 group-hover:text-white" />

              <p className="text-gray-200 text-xs md:text-base group-hover:text-white">
                {getHumanReadableDate(selectedDateRange.from || new Date())}
              </p>

              <p className="text-gray-300 text-xs md:text-base group-hover:text-white">
                to
              </p>
              <p className="text-gray-200 text-xs md:text-base group-hover:text-white">
                {getHumanReadableDate(selectedDateRange.to || new Date())}
              </p>
            </button>
          </div>
        </div>

        <div className="w-full md:w-auto flex flex-row space-x-2 md:space-x-4 place-items-center pt-24 md:hidden justify-center">
          <button
            onClick={openDatePicker}
            className="flex flex-row space-x-4 md:space-x-2 place-items-center justify-center bg-primary  border-solid text-black text-sm py-2 px-2 md:px-4 rounded-md hover:bg-primary group shadow-md"
          >
            <MdOutlineCalendarMonth className="text-2xl md:text-xl text-gray-200 group-hover:text-white" />

            <p className="text-gray-200 text-xl md:text-base group-hover:text-white">
              {getHumanReadableDate(selectedDateRange.from || new Date())}
            </p>

            <p className="text-gray-300 text-xl md:text-base group-hover:text-white">
              to
            </p>
            <p className="text-gray-200 text-xl md:text-base group-hover:text-white">
              {getHumanReadableDate(selectedDateRange.to || new Date())}
            </p>
          </button>
        </div>

        {isDatePickerOpen && (
          <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                <h2>Something went wrong</h2>
                <button onClick={resetErrorBoundary}>Try again</button>
              </div>
            )}
          >
            <CustomDatePicker
              currentDateRange={{
                startDate: selectedDateRange.from,
                endDate: selectedDateRange.to
              }}
              onClose={closeDatePicker}
              onDateChange={handleDateChange}
              from="Income Statement"
            />
          </ErrorBoundary>
        )}

        {incomeData && typeof incomeData !== "string" && (
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 justify-items-stretch items-center pt-6 mb-4 w-full justify-between">
            {/* income card */}
            <div className="max-w-sm w-full md:w-1/4 flex p-4 md:p-6 bg-white rounded-lg shadow-sm flex-col border-2">
              <div className="flex flex-row items-center justify-between">
                <div
                  className={`text-xl md:text-2xl outssnumbers ${
                    parseFloat(incomeData.total_income) >= 0
                      ? "text-green-500"
                      : "text-green-500"
                  }`}
                >
                  ${incomeData.total_income.toString() || 0}
                </div>
                {parseFloat(incomeData.total_income) >= 0 ? (
                  <div className="text-green-500">
                    <FaArrowUp className="text-green-500 text-xl md:text-2xl" />
                  </div>
                ) : (
                  <div className="text-red-500">
                    <FaArrowDown className="text-red-500 text-xl md:text-2xl" />
                  </div>
                )}
              </div>

              <div className="text-gray-500 text-base md:text-lg">
                Total Income
              </div>
            </div>

            {/* Cost of Goods Sold Card */}
            <div className="max-w-sm w-full md:w-1/4 flex p-4 md:p-6 bg-white rounded-lg shadow-sm flex-col border-2">
              <div className="flex flex-row items-center justify-between">
                <div
                  className={`text-xl md:text-2xl outssnumbers ${
                    parseFloat(incomeData.total_cogs) >= 0
                      ? "text-red-500"
                      : "text-green-500"
                  }`}
                >
                  ${incomeData.total_cogs?.toString() || 0}
                </div>
                {parseFloat(incomeData.total_cogs) >= 0 ? (
                  <div className="text-red-500">
                    <FaArrowUp className="text-red-500 text-xl md:text-2xl" />
                  </div>
                ) : (
                  <div className="text-green-500">
                    <FaArrowDown className="text-green-500 text-xl md:text-2xl" />
                  </div>
                )}
              </div>

              <div className="text-gray-500 text-base md:text-lg">
                Cost of Goods Sold
              </div>
            </div>

            {/* expenses card */}
            <div className="max-w-sm w-full md:w-1/4 flex p-4 md:p-6 bg-white rounded-lg shadow-sm flex-col border-2">
              <div className="flex flex-row items-center justify-between">
                <div
                  className={`text-xl md:text-2xl outssnumbers ${
                    parseFloat(incomeData.total_expenses) >= 0
                      ? "text-red-500"
                      : "text-green-500"
                  }`}
                >
                  ${incomeData.total_expenses.toString() || 0}
                </div>
                {parseFloat(incomeData.total_expenses) >= 0 ? (
                  <div className="text-red-500">
                    <FaArrowUp className="text-red-500 text-xl md:text-2xl" />
                  </div>
                ) : (
                  <div className="text-green-500">
                    <FaArrowDown className="text-green-500 text-xl md:text-2xl" />
                  </div>
                )}
              </div>

              <div className="text-gray-500 text-base md:text-lg">
                Total Expenses
              </div>
            </div>

            {/* net income card */}
            <div className="max-w-sm w-full md:w-1/4 flex p-4 md:p-6 bg-white rounded-lg shadow-sm flex-col border-2">
              <div className="flex flex-row items-center justify-between">
                <div
                  className={`text-xl md:text-2xl outssnumbers ${
                    parseFloat(incomeData.net_income) > 0
                      ? "text-primary"
                      : "text-primary"
                  }`}
                >
                  ${incomeData.net_income || 0}
                </div>
                {parseFloat(incomeData.net_income) > 0 ? (
                  <div className="text-primary">
                    <FaArrowUp className="ttext-primary text-xl md:text-2xl" />
                  </div>
                ) : (
                  <div className="text-primary">
                    <FaArrowDown className="text-primary text-xl md:text-2xl" />
                  </div>
                )}
              </div>

              <div className="text-gray-900 text-base md:text-lg">
                Net Profit
              </div>
            </div>
          </div>
        )}

        <div className="h-5" />

        {/* Incomes List*/}
        {incomeData && typeof incomeData !== "string" && incomeData.operating_income ? (
          <div className="bg-white rounded-lg shadow-sm p-4 md:p-6 border-2 mx-5 md:mx-0">
            <div className="flex flex-col mb-4">
              <div className="text-xl md:text-2xl font-bold mb-4">Incomes</div>
              {incomeData.operating_income.map(
                (category, index) => parseFloat(category?.amount) > 0 && (
                  <div key={index} className="mb-4">
                    <div
                      className="flex justify-between items-center cursor-pointer text-base md:text-lg"
                      onClick={() => toggleCategory(category.name)}
                    >
                      <div className="flex items-center">
                        <span className="text-gray-500">{category.name}</span>
                      </div>
                      <div className="text-green-700 outssnumbers">
                        ${category.amount}
                      </div>
                    </div>
                  </div>
                )
              )}
              <div className="flex justify-between items-center cursor-pointer text-base md:text-lg">
                <div className="flex items-center">
                  <span className="text-black text-bold">Total Income</span>
                </div>
                <div className="text-green-700 outssnumbers">
                  <p>
                    $
                    {isNaN(
                      incomeData.operating_income?.reduce(
                        (acc, cur) => acc + parseFloat(cur.amount),
                        0
                      )
                    )
                      ? 0
                      : incomeData.operating_income
                          ?.reduce(
                            (acc, cur) => acc + parseFloat(cur.amount),
                            0
                          )
                          .toString()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-gray-500 text-center text-base md:text-lg">
            No income statement data
          </div>
        )}

        <br />

        {/* Cost of Goods Sold List */}
        {incomeData && typeof incomeData !== "string" && incomeData.operating_expenses && (
          <div className="bg-white rounded-lg shadow-sm p-4 md:p-6 border-2 mx-5 md:mx-0">
            <div className="flex flex-col mb-4">
              <div className="text-xl md:text-2xl font-bold mb-4">
                Cost of Goods Sold
              </div>
              {incomeData.cogs_items.map((category, index) => (
                <div key={index} className="mb-4">
                  <div
                    className="flex justify-between items-center cursor-pointer text-base md:text-lg"
                    onClick={() => toggleCategory(category.name)}
                  >
                    <div className="flex items-center">
                      <span className="text-gray-500">{category.name}</span>
                    </div>
                    <div className="text-red-700 outssnumbers">
                      ${category.amount}
                    </div>
                  </div>
                </div>
              ))}
              <div className="flex justify-between items-center cursor-pointer text-base md:text-lg">
                <div className="flex items-center">
                  <span className="text-black text-bold">Total Cost of Goods Sold</span>
                </div>
                <div className="text-red-700 outssnumbers">
                  <p>
                    $
                    {isNaN(
                      incomeData.cogs_items?.reduce(
                        (acc, cur) => acc + parseFloat(cur.amount),
                        0
                      )
                    )
                      ? 0
                      : incomeData.cogs_items
                          ?.reduce(
                            (acc, cur) => acc + parseFloat(cur.amount),
                            0
                          )
                          .toString()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        <br />

        {/* Expenses List */}
        {incomeData && typeof incomeData !== "string" && incomeData.operating_expenses && (
          <div className="bg-white rounded-lg shadow-sm p-4 md:p-6 border-2 mx-5 md:mx-0">
            <div className="flex flex-col mb-4">
              <div className="text-xl md:text-2xl font-bold mb-4">
                Expenses
              </div>
              {incomeData.operating_expenses.map((category, index) => (
                <div key={index} className="mb-4">
                  <div
                    className="flex justify-between items-center cursor-pointer text-base md:text-lg"
                    onClick={() => toggleCategory(category.name)}
                  >
                    <div className="flex items-center">
                      <span className="text-gray-500">{category.name}</span>
                    </div>
                    <div className="text-red-700 outssnumbers">
                      ${category.amount}
                    </div>
                  </div>
                </div>
              ))}
              <div className="flex justify-between items-center cursor-pointer text-base md:text-lg">
                <div className="flex items-center">
                  <span className="text-black text-bold">Total Expenses</span>
                </div>
                <div className="text-red-700 outssnumbers">
                  <p>
                    $
                    {isNaN(
                      incomeData.operating_expenses?.reduce(
                        (acc, cur) => acc + parseFloat(cur.amount),
                        0
                      )
                    )
                      ? 0
                      : incomeData.operating_expenses
                          ?.reduce(
                            (acc, cur) => acc + parseFloat(cur.amount),
                            0
                          )
                          .toString()}
                  </p>
                </div>
              </div>
            </div>
          </div>
          )}

        <br />

        <div className="h-5" />
      </div>
    </div>
  );
};

export default IncomeStatement;
