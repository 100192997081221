import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getApiBaseUrl } from '../../../config/constants';
import '../DashboardRoot/dashboard.css';

interface BusinessDataType {
  id: number;
  businessName: string;
  legalStructure: string;
  taxIdNumber: string;
  address: string;
  contactInfo: string;
  ownerName: string;
  ownerContactInfo: string;
  preferredCommunication: string;
  industryType: string;
  businessModel: string;
  accountingSoftware: string;
  chartOfAccounts: boolean;
  desiredOutcomes: string;
  painPoints: string;
  user: number;
}

async function getUsers(): Promise<BusinessDataType[]> {
  const response = await fetch(`${getApiBaseUrl()}/business-details/`);
  const data = await response.json();
  return data;
}

async function getUserById(id: number): Promise<BusinessDataType> {
  const response = await fetch(`${getApiBaseUrl()}/business-details/${id}`);
  const data = await response.json();
  return data;
}

const AllClients: React.FC = () => {
  const [businesses, setBusinesses] = useState<BusinessDataType[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUsers();
      setBusinesses(data);
    };

    fetchData();
  }, []);

  return (
    <div className="overflow-x-auto">
      <h1 className="text-3xl font-bold mb-4 px-3 title ">All Clients</h1>
      <table className="w-full border-collapse mt-4">
        <thead>
          <tr>
            <th className="bg-gray-100 px-4 py-2 text-left font-bold">Business Name</th>
            <th className="bg-gray-100 px-4 py-2 text-left font-bold">Legal Structure</th>
            <th className="bg-gray-100 px-4 py-2 text-left font-bold">Industry Type</th>
            <th className="bg-gray-100 px-4 py-2 text-left font-bold">Business Model</th>
            <th className="bg-gray-100 px-4 py-2 text-left font-bold">Accounting Software</th>
            <th className="bg-gray-100 px-4 py-2 text-left font-bold">Details</th>
          </tr>
        </thead>
        <tbody>
          {businesses.map((business) => (
            <tr key={business.id} className="even:bg-gray-50">
              <td className="px-4 py-2">{business.businessName}</td>
              <td className="px-4 py-2">{business.legalStructure}</td>
              <td className="px-4 py-2">{business.industryType}</td>
              <td className="px-4 py-2">{business.businessModel}</td>
              <td className="px-4 py-2">{business.accountingSoftware}</td>
              <td className="px-4 py-2">
                <Link
                  to={`/accountant/business/${business.id}?userid=${business.user}`}
                  className="text-blue-500 hover:underline"
                >
                  View Details
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllClients;