import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getApiBaseUrl } from '../../../config/constants';
import { FiX } from 'react-icons/fi';

interface InvoiceFormData {
  billing_type: 'fixed' | 'percentage';
  fixed_amount: string;
  percentage: string;
  minimum_amount: string;
  total_amount: string;
  currency: string;
  status: 'pending' | 'paid' | 'failed';
}

interface Invoice extends InvoiceFormData {
  id: number;
  user: number;
}

interface PopupProps {
  message: string;
  onClose: () => void;
}

const Popup: React.FC<PopupProps> = ({ message, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black bg-opacity-50" onClick={onClose} />
      <div className="relative bg-white rounded-lg shadow-xl p-6 mx-4 transform transition-all">
        <div className="text-center">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
            {message.includes('Failed') ? <FiX className="h-6 w-6 text-red-600" /> : <svg className="h-6 w-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>}
          </div>
          <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
            {message}
          </h3>
          <button
            onClick={onClose}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const CreateInvoice: React.FC = () => {
  const location = useLocation();
  const [userid, setUserid] = useState<string | null>(null);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState<InvoiceFormData>({
    billing_type: 'fixed',
    fixed_amount: '',
    percentage: '',
    minimum_amount: '',
    total_amount: '',
    currency: 'USD',
    status: 'pending',
  });
  const [success, setSuccess] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const userIdFromUrl = searchParams.get('userid');
    setUserid(userIdFromUrl);

    if (userIdFromUrl) {
      fetchInvoices(userIdFromUrl);
    }
  }, [location.search]);

  const handleProcessPayment = async () => {
    if (!userid) {
      alert('User ID is missing');
      return;
    }
    try {
      setIsLoading(true)
      const response = await fetch(`${getApiBaseUrl()}/automatic-billing/?user_id=${userid}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user: userid }),
      });
      if (response.ok) {
        const result = await response.json();
        setSuccess(true)
        if (result.details[0].errors[0].includes("No pending invoices")) {
          setMessage("No pending invoices")          
        }else{
          setMessage(result.response)
        }
        fetchInvoices(userid);
        setIsLoading(false)
      } else {
        setIsLoading(false)
        throw new Error('Failed to process payment');
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error processing payment:', error);
      setSuccess(true)
      setMessage('Failed to process payment')
    }
  };


  const fetchInvoices = async (userId: string) => {
    setIsLoading(true)
    try {
      const response = await fetch(`${getApiBaseUrl()}/user-invoices/?userid=${userId}`);
      if (!response.ok) {
        setIsLoading(false)
        throw new Error('Failed to fetch invoices');
      }
      const data = await response.json();
      setInvoices(data);
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching invoices:', error);
      setIsLoading(false)
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!userid) {
      alert('User ID is missing');
      return;
    }
    try {
      setIsLoading(true)
      const response = await fetch(`${getApiBaseUrl()}/create-invoice/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          user: userid,
          total_amount: parseFloat(formData.total_amount),
          fixed_amount: formData.fixed_amount ? parseFloat(formData.fixed_amount) : null,
          percentage: formData.percentage ? parseFloat(formData.percentage) : null,
          minimum_amount: formData.minimum_amount ? parseFloat(formData.minimum_amount) : null,
        }),
      });
      if (response.ok) {
        const result = await response.json();
        console.log(result);
        setShowPopup(false);
        setSuccess(true)
        setMessage('Invoice Created Successfully')
        fetchInvoices(userid);
        setIsLoading(false)
      } else {
        const errorData = await response.json();
        setIsLoading(false)
        throw new Error(Object.entries(errorData).map(([key, value]) => `${key}: ${value}`).join(', '));
      }
    } catch (error) {
      console.error('Error creating invoice:', error);
      setIsLoading(false)
      alert(error instanceof Error ? error.message : 'An error occurred while creating the invoice');
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'pending':
        return '🕒';
      case 'paid':
        return '✅';
      case 'failed':
        return '❌';
      default:
        return '❓';
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'pending':
        return 'text-yellow-500';
      case 'paid':
        return 'text-green-500';
      case 'failed':
        return 'text-red-500';
      default:
        return 'text-gray-500';
    }
  };

  if (!userid) {
    return <div>Loading... or User ID not found</div>;
  }
  
  const handleClose = () => {
    setSuccess(false)
    setMessage('')
    setFormData({
      billing_type: 'fixed',
      fixed_amount: '',
      percentage: '',
      minimum_amount: '',
      total_amount: '',
      currency: 'USD',
      status: 'pending',
    })
  }

  if (isLoading) return <div className="flex items-center justify-center z-50 h-screen bg-white">
    <div className="flex flex-col items-center">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
      <p className="text-xl font-semibold text-primary mt-4">Loading...</p>
    </div>
  </div>

  return (
    <div className="max-w-6xl mx-auto p-4">
      {success && <Popup message={message} onClose={handleClose} />}
      <div className="flex justify-between mb-4 gap-4">
        <button
          onClick={() => setShowPopup(true)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Create New Invoice
        </button>
        <button
          onClick={handleProcessPayment}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Process Payment
        </button>
      </div>
      <div className='overflow-auto'>
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">ID</th>
              <th className="py-2 px-4 border-b">Billing Type</th>
              <th className="py-2 px-4 border-b">Total Amount</th>
              <th className="py-2 px-4 border-b">Currency</th>
              <th className="py-2 px-4 border-b">Status</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
              <tr key={invoice.id}>
                <td className="py-2 px-4 border-b text-center">{invoice.id}</td>
                <td className="py-2 px-4 border-b text-center">{invoice.billing_type}</td>
                <td className="py-2 px-4 border-b text-center">{invoice.total_amount}</td>
                <td className="py-2 px-4 border-b text-center">{invoice.currency}</td>
                <td className={`py-2 px-4 border-b text-center ${getStatusColor(invoice.status)}`}>
                  {getStatusIcon(invoice.status)} {invoice.status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showPopup && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
          <div className="relative top-20 mx-auto p-5 border w-96 rounded-md bg-white">
            <h3 className="text-lg font-bold mb-4">Create New Invoice</h3>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="billing_type" className="block text-sm font-medium text-gray-700">
                  Billing Type
                </label>
                <select
                  id="billing_type"
                  name="billing_type"
                  value={formData.billing_type}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="fixed">Fixed</option>
                  <option value="percentage">Percentage</option>
                </select>
              </div>

              {formData.billing_type === 'fixed' && (
                <div>
                  <label htmlFor="fixed_amount" className="block text-sm font-medium text-gray-700">
                    Fixed Amount
                  </label>
                  <input
                    type="number"
                    id="fixed_amount"
                    name="fixed_amount"
                    value={formData.fixed_amount}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              )}

              {formData.billing_type === 'percentage' && (
                <>
                  <div>
                    <label htmlFor="percentage" className="block text-sm font-medium text-gray-700">
                      Percentage
                    </label>
                    <input
                      type="number"
                      id="percentage"
                      name="percentage"
                      value={formData.percentage}
                      onChange={handleInputChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                  <div>
                    <label htmlFor="minimum_amount" className="block text-sm font-medium text-gray-700">
                      Minimum Amount
                    </label>
                    <input
                      type="number"
                      id="minimum_amount"
                      name="minimum_amount"
                      value={formData.minimum_amount}
                      onChange={handleInputChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </>
              )}

              <div>
                <label htmlFor="total_amount" className="block text-sm font-medium text-gray-700">
                  Total Amount
                </label>
                <input
                  type="number"
                  id="total_amount"
                  name="total_amount"
                  value={formData.total_amount}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  required
                />
              </div>

              <div>
                <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
                  Currency
                </label>
                <input
                  type="text"
                  id="currency"
                  name="currency"
                  value={formData.currency}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  required
                />
              </div>

              <div>
                <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                  Status
                </label>
                <select
                  id="status"
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="pending">Pending</option>
                  <option value="paid">Paid</option>
                  <option value="failed">Failed</option>
                </select>
              </div>

              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={() => setShowPopup(false)}
                  className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  Create Invoice
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateInvoice;
