import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { FaDownload } from "react-icons/fa";
import { MdOutlineCalendarMonth, MdClose } from "react-icons/md";
import Loader from "react-js-loader";
import { Calendar } from 'react-date-range';
import * as XLSX from 'xlsx-js-style';
import { saveAs } from 'file-saver';
import { enGB } from 'date-fns/locale';
import { getApiBaseUrl } from "../../../config/constants";
import mixpanel from "mixpanel-browser";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface ARDetail {
  date: string;
  transaction_type: string;
  ref_number: string;
  customer: string;
  due_date: string;
  amount: number;
  open_balance: number;
  aging_period: string;
}

interface ARResponse {
  success: boolean;
  report_date: string;
  currency: string;
  total_amount: number;
  invoice_count: number;
  ar_details: ARDetail[];
}

interface CustomerSummary {
  customer: string;
  current: number;
  thirty: number;
  sixty: number;
  ninety: number;
  ninetyPlus: number;
  total: number;
}

const fetchARDetails = async (userId: string | null, reportDate: string) => {
  const response = await fetch(
    `${getApiBaseUrl()}/users/${userId}/ar-details/?report_date=${reportDate}`,
    {
      headers: {
        'Authorization': `Token ${localStorage.getItem("token")}`,
      },
    }
  );
  const data = await response.json();
  return data.success ? data : null;
};

const ARAgingReport = () => {
  const [showDownload, setShowDownload] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  
  const userId = localStorage.getItem("userId") || null;
  const reportDate = selectedDate.toISOString().split('T')[0];

  const {
    data: arData,
    isLoading,
    isError,
  } = useQuery<ARResponse | null>(
    ["arAging", userId, reportDate],
    () => fetchARDetails(userId, reportDate),
    {
      enabled: !!userId,
      staleTime: 60 * 1000,
    }
  );

  useEffect(() => {
    mixpanel.track('AR Aging Report Visited');
  }, []);

  useEffect(() => {
    if (arData?.ar_details && arData.ar_details.length > 0) {
      setShowDownload(true);
    } else {
      setShowDownload(false);
    }
  }, [arData]);

  function detectPlatform() {
    var userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window) {
      return "mobile";
    }
    return "web";
  }

  const calculateCustomerSummaries = (details: ARDetail[] = []): CustomerSummary[] => {
    if (!Array.isArray(details) || details.length === 0) {
      return [];
    }

    const customerMap = new Map<string, CustomerSummary>();

    details.forEach(detail => {
      if (!customerMap.has(detail.customer)) {
        customerMap.set(detail.customer, {
          customer: detail.customer,
          current: 0,
          thirty: 0,
          sixty: 0,
          ninety: 0,
          ninetyPlus: 0,
          total: 0
        });
      }

      const summary = customerMap.get(detail.customer)!;
      
      switch (detail.aging_period) {
        case "Current":
          summary.current += detail.open_balance;
          break;
        case "1 - 30 days past due":
          summary.thirty += detail.open_balance;
          break;
        case "31 - 60 days past due":
          summary.sixty += detail.open_balance;
          break;
        case "61 - 90 days past due":
          summary.ninety += detail.open_balance;
          break;
        case "91 or more days past due":
          summary.ninetyPlus += detail.open_balance;
          break;
      }
      
      summary.total += detail.open_balance;
    });

    return Array.from(customerMap.values());
  };

  const generateExcel = () => {
    if (!arData?.ar_details?.length) return;

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([]);

    const addRow = (row: (string | number)[], styles: XLSX.CellStyle = {}) => {
      XLSX.utils.sheet_add_aoa(ws, [row], { origin: -1 });
      const range = XLSX.utils.decode_range(ws['!ref'] || 'A1');
      const rowIndex = range.e.r;
      for (let i = 0; i < row.length; i++) {
        const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: i });
        if (ws[cellRef]) {
          ws[cellRef].s = styles;
        }
      }
    };

    const boldStyle: XLSX.CellStyle = { font: { bold: true } };
    const headerStyle: XLSX.CellStyle = { 
      font: { bold: true },
      fill: { fgColor: { rgb: "EEEEEE" } }
    };

    addRow(['Accounts Receivable Aging Summary Report'], boldStyle);
    addRow([`As of ${arData.report_date}`], boldStyle);
    addRow([]);

    const headers = [
      'Customer',
      'Current',
      '1-30',
      '31-60',
      '61-90',
      'Over 90',
      'Total'
    ];
    addRow(headers, headerStyle);

    const summaries = calculateCustomerSummaries(arData.ar_details);
    
    summaries.forEach(summary => {
      addRow([
        summary.customer,
        summary.current.toFixed(2),
        summary.thirty.toFixed(2),
        summary.sixty.toFixed(2),
        summary.ninety.toFixed(2),
        summary.ninetyPlus.toFixed(2),
        summary.total.toFixed(2)
      ]);
    });

    if (summaries.length > 0) {
      const totals = summaries.reduce((acc, curr) => ({
        customer: 'Total',
        current: acc.current + curr.current,
        thirty: acc.thirty + curr.thirty,
        sixty: acc.sixty + curr.sixty,
        ninety: acc.ninety + curr.ninety,
        ninetyPlus: acc.ninetyPlus + curr.ninetyPlus,
        total: acc.total + curr.total
      }));

      addRow([
        totals.customer,
        totals.current.toFixed(2),
        totals.thirty.toFixed(2),
        totals.sixty.toFixed(2),
        totals.ninety.toFixed(2),
        totals.ninetyPlus.toFixed(2),
        totals.total.toFixed(2)
      ], boldStyle);
    }

    const range = XLSX.utils.decode_range(ws['!ref'] || 'A1');
    const cols = Array(range.e.c + 1).fill(null).map((_, i) => ({
      wch: i === 0 ? 30 : 15
    }));
    ws['!cols'] = cols;

    XLSX.utils.book_append_sheet(wb, ws, "AR Aging");
    
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { 
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    
    saveAs(data, `ar_aging_${arData.report_date}.xlsx`);
    mixpanel.track('AR Aging Report Excel Downloaded');
  };

  if (isLoading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Loader
          type="bubble-top"
          bgColor="#69339C"
          color="#69339C"
          title="Loading AR data..."
          size={20}
        />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="p-4 text-red-600">
        Error occurred while fetching AR aging report.
      </div>
    );
  }

  const customerSummaries = calculateCustomerSummaries(arData?.ar_details);
  const totals = customerSummaries.length > 0 
    ? customerSummaries.reduce((acc, curr) => ({
        customer: 'Total',
        current: acc.current + curr.current,
        thirty: acc.thirty + curr.thirty,
        sixty: acc.sixty + curr.sixty,
        ninety: acc.ninety + curr.ninety,
        ninetyPlus: acc.ninetyPlus + curr.ninetyPlus,
        total: acc.total + curr.total
      }))
    : null;

  return (
    <div className="p-4 md:p-6">
      <div className="flex justify-between items-center mb-6">
        <div className="space-y-2">
          <h1 className="text-2xl font-bold">
            Accounts Receivable Aging Summary Report
          </h1>
        </div>
        
        <div className="flex space-x-4 items-center">
          <span className="text-gray-600 text-sm font-medium">Select Date:</span>
          <button
            onClick={() => setIsDatePickerOpen(true)}
            className="flex items-center bg-primary text-gray-200 px-4 py-2 rounded-md hover:bg-primary group shadow-md transition-colors duration-200"
          >
            <MdOutlineCalendarMonth className="mr-2 text-xl group-hover:text-white" />
            <span className="group-hover:text-white">
              {selectedDate.toLocaleDateString()}
            </span>
          </button>
          
          {showDownload && (
            <button
              onClick={generateExcel}
              className="bg-white text-primary px-4 py-2 rounded-md hover:bg-primary hover:text-white flex items-center border border-primary shadow-md transition-colors duration-200"
            >
              <FaDownload className="mr-2" />
              Download (Excel)
            </button>
          )}
        </div>
      </div>

      {isDatePickerOpen && (
        <div className="fixed inset-0 bg-gray-300 bg-opacity-80 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg z-10 w-full md:w-auto border py-4 md:py-0">
            <div className="flex flex-col items-center">
              <div className="w-full">
                <div className="flex justify-between items-center px-4 py-2">
                  <p className="text-lg font-bold">Select Date</p>
                  <MdClose 
                    className="text-2xl cursor-pointer" 
                    onClick={() => setIsDatePickerOpen(false)} 
                  />
                </div>
                <hr className="border-t border-gray-200" />
                
                <Calendar
                  date={selectedDate}
                  onChange={(date) => {
                    setSelectedDate(date);
                    mixpanel.track('AR Aging Report Date Selected', {
                      email: localStorage.getItem("mailId"),
                      platform: detectPlatform()
                    });
                  }}
                  locale={enGB}
                  color="#69339C"
                  maxDate={new Date()}
                />
              </div>
              <div className="w-full p-4 flex justify-end space-x-4">
                <button
                  className="px-4 py-2 border border-primary text-gray-600 rounded"
                  onClick={() => setIsDatePickerOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-primary text-white rounded hover:bg-primary/90"
                  onClick={() => setIsDatePickerOpen(false)}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      
      <div className="text-gray-600 mb-6">
        Report as of {arData?.report_date || reportDate}
      </div>

      {customerSummaries.length === 0 ? (
        <div className="text-center py-8 text-gray-500">
          No AR data available for the selected date.
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border rounded-lg">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Customer
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Current
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  1-30
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  31-60
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  61-90
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Over 90
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {customerSummaries.map((summary, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {summary.customer}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                    ${summary.current.toFixed(2)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                    ${summary.thirty.toFixed(2)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                    ${summary.sixty.toFixed(2)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                    ${summary.ninety.toFixed(2)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                    ${summary.ninetyPlus.toFixed(2)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                    ${summary.total.toFixed(2)}
                  </td>
                </tr>
              ))}
              {totals && (
                <tr className="bg-gray-100">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {totals.customer}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                    ${totals.current.toFixed(2)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                    ${totals.thirty.toFixed(2)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                    ${totals.sixty.toFixed(2)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                    ${totals.ninety.toFixed(2)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                    ${totals.ninetyPlus.toFixed(2)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                    ${totals.total.toFixed(2)}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default ARAgingReport;