import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCheck, FaTimes } from 'react-icons/fa';

const Logout = () => {
  const navigate = useNavigate();

  const confirmLogout = () => {
    localStorage.clear();
    navigate('/');
    if (window.location.pathname.includes('accountant')) {
      navigate('/accountant/login');
    } else {
    navigate('/');
    }
    
  };

  const cancelLogout = () => {
    if (window.location.pathname.includes('accountant')) {
      navigate('/accountant/dashboard');
    } else {
    navigate('/dashboard');
    }
  };

  return (
    <div className="min-h-screen bg-white flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4">Confirm Logout</h2>
        <p className="mb-6">Are you sure you want to logout?</p>
        <div className="flex justify-center">
          <button
            className="px-4 py-2 bg-green-500 text-white rounded-lg mr-4 hover:bg-green-600 focus:outline-none transition duration-300"
            onClick={confirmLogout}
          >
            <FaCheck className="inline-block mr-2" />
            Yes
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none transition duration-300"
            onClick={cancelLogout}
          >
            <FaTimes className="inline-block mr-2" />
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default Logout;