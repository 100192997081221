import { ResponsiveBar } from "@nivo/bar";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { getApiBaseUrl } from "../../../../config/constants";

type DataItem = {
  month: string;
  income: number;
  expense: number;
};

const generateRandomData = (): DataItem[] => {
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  return months.map(month => {
    const income = Math.floor(Math.random() * 10000) + 1000;
    const expense = Math.floor(Math.random() * 9000) + 1000;
    return { month, income, expense: expense + (income - expense) };
  });
};

const sampleData: DataItem[] = generateRandomData();

const ProfitGraph: React.FC = () => {
  const [data, setData] = useState<DataItem[]>([]);
  const [isDemo, setIsDemo] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem("userId") || 1;
        const response = await axios.get(
          `${getApiBaseUrl()}/stats/report/${userId}/?start_date=2024-01-01&end_date=2024-11-30`
        );
        const apiData = response.data.response;
        
        if (apiData && apiData.length > 0) {
          const formattedData = apiData.map(
            (item: {
              month: string;
              "Total Income": string;
              "Total Expenses": string;
              "Net Income": string;
            }) => ({
              month: item.month.split(", ")[0],
              income: parseFloat(item["Total Income"]),
              expense: parseFloat(item["Total Expenses"] || "0") + parseFloat(item["Net Income"]),
            })
          );

          // adding missing months
          const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
          ];
          const dataWithDummyMonths = months.map((month) => {
            const existingData = formattedData.find(
              (item: any) => item.month === month
            );
            return existingData || { month, income: 0, expense: 0 };
          });

          setData(dataWithDummyMonths);
          setIsDemo(false);
        } else {
          setData(sampleData);
          setIsDemo(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setData(sampleData);
        setIsDemo(true);
      }
    };

    fetchData();
  }, []);

  const formatValue = (value: number) => {
    return `$${value.toLocaleString()}`;
  };

  const displayData = isMobile ? data.slice(0, 5) : data;

  return (
    <div className="container mx-auto p-4 bg-white rounded-md border w-11/12 md:w-full">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Account at a glance</h2>
        {isDemo && (
          <span className="px-2 py-1 bg-blue-100 text-blue-800 text-xs font-medium rounded">
            Demo Data
          </span>
        )}
      </div>
      <div className="responsive-container h-[32rem] md:h-96 profit-loss-menu">
        <ResponsiveBar
          data={displayData}
          keys={["income", "expense"]}
          indexBy="month"
          margin={{ top: 20, right: 10, bottom: 20, left: 60 }}
          padding={0.05}
          innerPadding={0.1}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={["#69339C", "#4ECDC4"]}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          groupMode="grouped"
          axisBottom={{
            tickSize: 1,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Month",
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={{
            legendPosition: "middle",
            legendOffset: isMobile ? 100 : -1000,
            format: formatValue,
            tickSize: 12,
            tickPadding: 5,
            tickValues: 5,
          }}
          enableLabel={false}
          labelSkipWidth={1}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          legends={[
            {
              dataFrom: "keys",
              anchor: isMobile ? "bottom" : "bottom-right",
              direction: isMobile ? "row" : "column",
              justify: false,
              translateX: 0,
              translateY: isMobile ? 40 : 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
          ariaLabel="Profit Graph"
          barAriaLabel={(e) =>
            `${e.id}: ${e.formattedValue} in ${e.indexValue}`
          }
        />
      </div>
    </div>
  );
};

export default ProfitGraph;